import React from 'react';

import { Link } from 'react-router-dom';

import Divider from "../../resources/borders/divider-2.png";
import HireButton from "../../resources/buttons/hire-button.png";
import Check from "../../resources/elements/check-mark.png";
import Background from "../../resources/backgrounds/bg-thumbs-up.webp";
import BackgroundMobile from "../../resources/backgrounds/bg-thumbs-up-mobile.webp";

import './ReasonsSection.scss';

const ReasonsSection = (props) => {
    const {
        reasonsToHire,
        mode
    } = props;

    return (
        <div className={`reasonsSection ${mode}`}>
            <div className={`reasonsSection__top seperator__padding ${mode}`}>
                <div className='imgContainer'>
                    <img loading="lazy"
                        src={Divider}
                        alt="divider"
                    ></img>
                </div>
                <Link to={'/contact'}>
                    <div className='hireContainer button'>
                        <div className='hireButtonContainer' >
                            <img loading="lazy"
                                src={HireButton}
                                alt="hire RUBII GRAPHICS"
                            ></img>
                        </div>
                    </div>
                </Link>
                <div className='imgContainer'>
                    <img loading="lazy"
                        src={Divider}
                        alt="divider"
                    ></img>
                </div>
            </div>
            <div className={`reasonsSection__bottom ${mode}`}>
                <div className='imgContainer'>
                    <img loading="lazy"
                        src={mode.includes('mobile') ? BackgroundMobile : Background}
                        alt="background thumps up"
                    ></img>
                </div>

                <div className={`reasonsSection__textContainer ${mode}`}>
                    <div className='reasonsSection__textContainer__header'>
                        Why choose
                        <br />
                        <span style={{ fontSize: 45, textAlign: 'right' }}>
                            {!mode.includes('mobile') && <span>&nbsp;&nbsp;</span>}
                            RUBII GRAPHICS?
                        </span>
                    </div>
                    <div className='reasonsSection__textContainer__body'>
                        {reasonsToHire.map((item) => {
                            return (
                                <div
                                    key={item}
                                    className='vertical__list__item'
                                >
                                    <img loading="lazy"
                                        src={Check}
                                        alt="check"
                                        height={75}
                                    ></img>
                                    {item}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReasonsSection;