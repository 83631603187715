import React, { useEffect, useState } from 'react';

import Modal from "@mui/material/Modal";
import Edge from '../resources/borders/edge.png';
import Arrow from "../resources/buttons/arrow.png";
import ModalArrow from "../resources/buttons/modal-arrow.png";

import './ImageModal.scss';

const ImageModal = (props) => {
    const {
        show,
        images,
        currIndex,
        onClose,
        mode
    } = props;

    const [backIsFocused, setBackFocus] = useState(false);
    const [arrowIsFocused, setArrowFocus] = useState('');
    const [index, setIndex] = useState(currIndex || 0);

    let backButtonMaxWidthHeight = mode.includes('desktop')
        ? 90 : 65;
    if (backIsFocused) {
        backButtonMaxWidthHeight = backButtonMaxWidthHeight * 1.1
    } else {
        backButtonMaxWidthHeight = mode.includes('desktop')
            ? 90 : 65;
    }

    const onNavigationClick = (direction) => {
        if (direction === 'left') {
            if (index > 0) {
                setIndex(index - 1);
            }
        } else if (direction === 'right') {
            if (index < images.length - 1) {
                setIndex(index + 1)
            }
        }
    }

    useEffect(() => {
        setIndex(currIndex || 0);
    }, [currIndex]);

    return (
        <Modal
            open={show}
        >
            <div className={`modal__container ${mode}`}>
                <div className={`modal__top ${mode}`}>
                    <div className='modal__backContainer button'>
                        <div className="modal__backContainer button"
                            onMouseEnter={() => setBackFocus(true)}
                            onMouseLeave={() => setBackFocus(false)}
                            onClick={() => onClose()}
                        >
                            <div className="modal__backImgContainer imgContainer">
                                <img loading="lazy"
                                    style={{
                                        height: `${backButtonMaxWidthHeight}%`,
                                        width: `${backButtonMaxWidthHeight}%`,
                                        marginLeft: backIsFocused
                                            ? 5 : 20
                                    }}
                                    src={Arrow}
                                    alt="left arrow"
                                ></img>
                            </div>
                            <span className={'modal__backText' + (backIsFocused ? ' focused' : '')}>Back</span>
                        </div>
                    </div>
                    <div className='edges__container'>
                        <div className='imgContainer'>
                            <img
                                src={Edge}
                                alt="corner"
                                style={{
                                    rotate: "180deg"
                                }}
                            ></img>
                        </div>
                        <div className='imgContainer'>
                            <img
                                src={Edge}
                                alt="corner"
                                style={{
                                    rotate: "180deg",
                                    transform: "scaleX(-1)"
                                }}
                            ></img>
                        </div>
                    </div>
                </div>
                <div className={`modal__content ${mode}`}>
                    <div className='image__container'>
                        <div className='imgContainer'>
                            <img
                                src={images[index].src}
                                alt="sample"
                            ></img>
                        </div>
                    </div>
                    {images[index].text && <div className='modal__text'>{images[index].text}</div>}
                </div>
                <div className={`modal__bottom ${mode}`}>
                    <div className='edges__container'>
                        <div className='imgContainer'>
                            <img
                                src={Edge}
                                alt="corner"
                                style={{
                                    transform: "scaleX(-1)"
                                }}
                            ></img>
                        </div>
                        <div className={`modal__buttons ${mode}`}>
                            <div className='modal__buttons__container'>
                                {index !== 0
                                    ? <div className='imgContainer button'
                                        onMouseEnter={() => setArrowFocus('left')}
                                        onMouseLeave={() => setArrowFocus('')}
                                        onClick={() => onNavigationClick('left')}
                                    >
                                        <img
                                            style={{
                                                height: `${arrowIsFocused === 'left' ? 100 : 90}%`,
                                                width: `${arrowIsFocused === 'left' ? 100 : 90}%`,
                                            }}
                                            src={ModalArrow}
                                            alt='left arrow'
                                        ></img>
                                    </div>
                                    :
                                    <div>
                                    </div>
                                }
                                {index < images.length - 1 &&
                                    <div className='imgContainer button'
                                        onMouseEnter={() => setArrowFocus('right')}
                                        onMouseLeave={() => setArrowFocus('')}
                                        onClick={() => onNavigationClick('right')}
                                    >
                                        <img
                                            src={ModalArrow}
                                            alt='right arrow'
                                            style={{
                                                transform: "scaleX(-1)",
                                                height: `${arrowIsFocused === 'right' ? 100 : 90}%`,
                                                width: `${arrowIsFocused === 'right' ? 100 : 90}%`
                                            }}
                                        ></img>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='imgContainer'>
                            <img
                                src={Edge}
                                alt="corner"
                            ></img>
                        </div>
                    </div>
                </div>
                {mode.includes('mobile') && <div></div>}
            </div>
        </Modal >
    )
}

export default ImageModal;