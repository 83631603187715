import React from 'react';

export const importAll = (r, source) => {
    let result = [];
    let array = r.keys().map(r);
    for (let i = 0; i < array.length; i++) {
        let text = null;
        if (source === 'marketdesign' && i === 6) {
            text = <React.Fragment>
                {`Used with permission. See `}
                <a
                    href="https://neverendingradioshow.com/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Https://Neverendingradioshow.com/
                </a>
            </React.Fragment>
        } else if (source === 'illustration' && i === 0) {
            text = <a
                href="https://x.com/sonofsonnet/status/1719538508400914918?s=20"
                target="_blank"
                rel="noreferrer"
            >
                Https://x.com/sonofsonnet/status/1719538508400914918?s=20
            </a>
        }
        result.push({
            src: array[i],
            index: i,
            text: text
        })
    }

    return result;
}
