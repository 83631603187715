import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import axios from 'axios';

import Modal from "./NotifModal";

import Header from "../resources/headers/contact-header.webp";
import Edge from "../resources/borders/edge.png";
import ORLineVertical from "../resources/elements/or-line-vertical.png";
import OrLineHorizontal from "../resources/elements/or-line-horizontal.png";
import SendButton from "../resources/buttons/send-button.png";
import SendButtonMobile from "../resources/buttons/send-button-mobile.png";
import Arrow from "../resources/buttons/arrow.png";
import HomeIcon from "../resources/icons/home-icon.png";

import './Contact.scss';

const Contact = (props) => {
    const pageTitle = "contact";
    const {
        pageOptions,
        contactOptions,
        onBackButtonClick,
        mode
    } = props;

    const [homeIsFocused, setHomeFocus] = useState(false);
    const [sendIsFocused, setSendFocus] = useState(false);
    const [backIsFocused, setBackFocus] = useState(false);
    const [error, setError] = useState([]);
    const [form, setForm] = useState({
        message: '',
        email: ''
    });

    const [sendResponse, setSendResponse] = useState({
        show: false,
        header: '',
        body: ''
    });

    let homeButtonMaxWidthHeight = 90;
    if (homeIsFocused) {
        homeButtonMaxWidthHeight = homeButtonMaxWidthHeight * 1.1
    } else {
        homeButtonMaxWidthHeight = 90;
    }

    let sendButtonMaxWidthHeight = mode.includes('mobile')
        ? 100 : 90;
    if (sendIsFocused) {
        sendButtonMaxWidthHeight = sendButtonMaxWidthHeight * 1.1
    } else {
        sendButtonMaxWidthHeight = mode.includes('mobile')
            ? 100 : 90;
    }

    let backButtonMaxWidthHeight = 90;
    if (backIsFocused) {
        backButtonMaxWidthHeight = backButtonMaxWidthHeight * 1.1
    } else {
        backButtonMaxWidthHeight = 90;
    }

    const handleSubmit = () => {
        let url = 'https://www.rubiigraphics.com/index.php';

        const errors = [];

        // Make sure email is not empty
        if (form.email === '') {
            errors.push('Please enter your email.')
        } else if ( // Validate email
            !form.email.includes('@')
            || (form.email.includes('@')
                && form.email.split('@').length >= 2
                && !form.email.split('@')[1].includes('.'))
        ) {
            errors.push('Please enter a valid email.')
        }

        // Make sure message is not empty
        if (form.message === '') {
            errors.push('Cannot send empty message!')
        }

        if (errors.length === 0) {
            axios({
                method: "post",
                url: `${url}`,
                headers: { "content-type": "application/json" },
                data: form
            })
                .then(() => {
                    setSendResponse({
                        show: true,
                        header: 'Message Sent!',
                        body: 'Allow 1-2 business days to respond'
                    });

                    // Reset form
                    setForm({
                        message: '',
                        email: ''
                    })
                })
                .catch(() => {
                    setSendResponse({
                        show: true,
                        header: 'There was an error',
                        body: 'Please try again later'
                    });
                });
        }
        setError(errors);
    }

    const onModalClose = () => {
        setSendResponse({
            show: false
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={`contact ${mode}`}>
            <div className={`contact__container ${mode}`}>
                <div className={`contact__header ${mode}`}>
                    <div className='navigation__container'>
                        <div className={`navigation ${mode}`}>
                            {pageOptions.map((item) => {
                                return (
                                    <React.Fragment key={`logo-${item.name}`}>
                                        {item.name.toLowerCase() !== pageTitle && <div
                                            className="navigation__item"
                                        >
                                            {item.type === 'link'
                                                ? <Link to={item.link}>{item.name}</Link>
                                                : <HashLink to={item.link}>{item.name}</HashLink>
                                            }
                                        </div>
                                        }
                                    </React.Fragment>
                                )
                            })}
                        </div>
                        <div className={`homeContainer ${mode}`}>
                            <Link to="/">
                                <div className='imgContainer button'
                                    onMouseEnter={() => setHomeFocus(true)}
                                    onMouseLeave={() => setHomeFocus(false)}
                                >
                                    <img loading="lazy"
                                        style={{
                                            height: `${homeButtonMaxWidthHeight}%`,
                                            width: `${homeButtonMaxWidthHeight}%`
                                        }}
                                        src={HomeIcon}
                                        alt="home"
                                    ></img>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='contact__header__content'>
                        <div className="contact__header__edge imgContainer">
                            <img loading="lazy"
                                src={Edge}
                                alt="top left border"
                            ></img>
                        </div>
                        <div className="contact__header__middle">
                            <div className="imgContainer">
                                <img loading="lazy"
                                    src={Header}
                                    alt="Contact"
                                ></img>
                            </div>
                        </div>
                        <div className="contact__header__edge imgContainer"
                            style={{ opacity: 0 }}>
                            <img loading="lazy"
                                src={Edge}
                                alt="top left border"
                            ></img>
                        </div>
                    </div>
                </div>
                <div className={`contact__body ${mode} container__largeMaxWidth`}>
                    <div className="contact__body__left">
                        <div className="contact__options__list">
                            {contactOptions.map((item) => {
                                return (
                                    <div key={item.type}
                                        className="contact__options__list__item"
                                    >
                                        <div className="imgContainer">
                                            <img loading="lazy"
                                                src={item.icon}
                                                alt={item.type}
                                            ></img>
                                        </div>
                                        {item.text}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="contact__body__middle">
                        <div className="imgContainer">
                            <img loading="lazy"
                                src={
                                    mode.includes('desktop')
                                        ? ORLineVertical
                                        : OrLineHorizontal
                                }
                                alt="OR"
                            ></img>
                        </div>
                    </div>
                    <div className="contact__body__right">
                        <span>Type up a message right now!</span>
                        <div className="contact__inputContainer">
                            <input
                                type='email'
                                placeholder='email'
                                value={form.email}
                                onChange={(e) => setForm({ ...form, email: e.target.value })}
                                required
                            />
                        </div>
                        <div className="contact__textContainer">
                            <textarea
                                placeholder='message'
                                onChange={(e) => setForm({ ...form, message: e.target.value })}
                                value={form.message}
                                required
                            />
                        </div>
                        {error.length > 0
                            && error.map((msg) => (
                                <div key={msg}>{msg}</div>
                            ))
                        }
                        <div className="contact__sendButtonContainer">
                            <div className="imgContainer button"
                                onMouseEnter={() => setSendFocus(true)}
                                onMouseLeave={() => setSendFocus(false)}
                                onClick={() => handleSubmit()}
                            >
                                <img loading="lazy"
                                    style={{
                                        maxHeight: `${sendButtonMaxWidthHeight}%`,
                                        maxWidth: `${sendButtonMaxWidthHeight}%`
                                    }}
                                    src={mode.includes('mobile')
                                        ? SendButtonMobile
                                        : SendButton}
                                    alt="send message"
                                ></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`contact__footer ${mode}`}>
                    {mode.includes('desktop')
                        && <div className="navigation__back"
                            style={{
                                height: '100%',
                                display: 'flex',
                                flexFlow: 'row'
                            }}>
                            <div className="backContainer button"
                                onMouseEnter={() => setBackFocus(true)}
                                onMouseLeave={() => setBackFocus(false)}
                                onClick={() => onBackButtonClick()}
                            >
                                <div className="backImgContainer imgContainer">
                                    <img loading="lazy"
                                        style={{
                                            height: `${backButtonMaxWidthHeight}%`,
                                            width: `${backButtonMaxWidthHeight}%`,
                                            marginLeft: backIsFocused ? 5 : 20
                                        }}
                                        src={Arrow}
                                        alt="left arrow"
                                    ></img>
                                </div>
                                <span className={'backText' + (backIsFocused ? ' focused' : '')}>Back</span>
                            </div>
                        </div>
                    }
                    <div className="imgContainer">
                        <img loading="lazy"
                            src={Edge}
                            alt="bottom right border"
                        ></img>
                    </div>
                </div>
            </div>
            <Modal
                show={sendResponse.show}
                bodyText={sendResponse.body}
                headerText={sendResponse.header}
                onModalClose={onModalClose}
            />
        </div>
    );
}

export default Contact;
