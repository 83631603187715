import React, { useState } from 'react';
import { importAll } from '../../utils';
import Modal from "../ImageModal";

import PackageDeals from "../../resources/services/illustration/package-deals.webp";

import './Illustrations.scss';

const boxes = importAll(require.context('../../resources/services/illustration/boxes', false, /\.(webp)$/), 'illustration');
const images = importAll(require.context('../../resources/services/illustration/images', false, /\.(webp)$/), 'illustration');

const Illustrations = (props) => {
    const {
        mode
    } = props;
    const [boxIsFocused, setBoxFocus] = useState(-1);
    const [currImageIndex, setImageIndex] = useState(null);

    let boxMaxWidthHeight = 97;
    let expandBoxMaxWidthHeight = 100;

    const onBoxClick = (img) => {
        if (img) {
            setImageIndex(img.index);
        }
    }


    return (
        <>
            <div className={`illustrations__content ${mode}`}>
                <div className={`illustrations__body whiteText ${mode} ` + (
                    mode.includes('desktop')
                        ? 'container__largeMaxWidth' : ''
                )}>
                    {!mode.includes('desktop')
                        && <div className='illustrations__body__top'>
                            <div className='mainTitleText lgText centerAlign'>
                                Pricing Information
                            </div>
                            <div className='centerAlign'>
                                <div className='titleText mText'>Illustration (Sketched)</div>
                                <div className='bodyText sText'>$140</div>
                            </div>
                            <div className='centerAlign'>
                                <div className='titleText mText'>Illustration (Color)</div>
                                <div className='bodyText sText'>$200</div>
                            </div>
                        </div>
                    }
                    <div className={`illustrations__body__content ${mode}`}>
                        <div className={`illustrations__body__left ${mode}`}>
                            {mode.includes('desktop')
                                && <React.Fragment>
                                    <div className='mainTitleText lgText centerAlign'>
                                        Pricing Information
                                    </div>
                                    <div className='centerAlign'>
                                        <div className='titleText mText'>Illustration (Sketched)</div>
                                        <div className='bodyText sText'>$140</div>
                                    </div>
                                    <div className='centerAlign'>
                                        <div className='titleText mText'>Illustration (Color)</div>
                                        <div className='bodyText sText'>$200</div>
                                    </div>
                                </React.Fragment>
                            }
                            {mode.includes('mobile') &&
                                <div className='boxes__container'>
                                    {boxes.map((image, index) => {
                                        if (index === 1) {
                                            image = boxes[2]
                                        }
                                        if (index === 2) {
                                            image = boxes[1]
                                        }
                                        return (
                                            <div key={image.src} className='imgContainer button'
                                                onMouseEnter={() => setBoxFocus(image.src)}
                                                onMouseLeave={() => setBoxFocus(-1)}
                                                onClick={() => onBoxClick(image)}
                                            >
                                                <img
                                                    src={image.src}
                                                    style={{
                                                        height: `100%`,
                                                        width: `100%`
                                                    }}
                                                    alt="illustration example"
                                                ></img>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                            <div className='imgContainer packageDeals__container'>
                                <img loading="lazy"
                                    src={PackageDeals}
                                    alt="package deals"
                                ></img>
                            </div>
                        </div>
                        {!mode.includes('mobile')
                            && <div className={`illustratons__body__right ${mode}`}>
                                <div className='boxes__container'>
                                    {boxes.map((image) => {
                                        return (
                                            <div key={image.src} className='imgContainer button'
                                                onMouseEnter={() => setBoxFocus(image.src)}
                                                onMouseLeave={() => setBoxFocus(-1)}
                                                onClick={() => onBoxClick(image)}
                                            >
                                                <img
                                                    src={image.src}
                                                    style={{
                                                        height: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`,
                                                        width: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`
                                                    }}
                                                    alt="illustration example"
                                                ></img>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Modal
                show={currImageIndex !== null}
                images={images}
                currIndex={currImageIndex || 0}
                onClose={() => setImageIndex(null)}
                mode={mode}
            />
        </>
    )
}

export default Illustrations;