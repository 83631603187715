import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import Header from "../resources/headers/about-header.webp";
import Divider from "../resources/borders/divider.png";
import Background from "../resources/backgrounds/bg-us.webp";
import BackgroundMobile from "../resources/backgrounds/bg-us-mobile.webp";
import Profile from "../resources/graphics/pp.webp";
import Medal from "../resources/graphics/medal-graphic.webp";
import Sig from "../resources/graphics/signature.webp"
import Arrow from "../resources/buttons/arrow.png";
import HomeIcon from "../resources/icons/home-icon.png";

import './About.scss';

const About = (props) => {
    const pageTitle = "about us";
    const {
        pageOptions,
        onBackButtonClick,
        mode
    } = props;

    const firstList = [
        "Website Design",
        "Logo Design",
        "Marketing Design",
        "Social Media Design"
    ];
    const secondList = [
        "Characters",
        "Props",
        "Illustrations",
        "And more !"
    ];

    const [homeIsFocused, setHomeFocus] = useState(false);

    let homeButtonMaxWidthHeight = 90;
    if (homeIsFocused) {
        homeButtonMaxWidthHeight = homeButtonMaxWidthHeight * 1.1
    } else {
        homeButtonMaxWidthHeight = 90;
    }

    const [backIsFocused, setBackFocus] = useState(false);
    let backButtonMaxWidthHeight = 90;
    if (backIsFocused) {
        backButtonMaxWidthHeight = backButtonMaxWidthHeight * 1.1
    } else {
        backButtonMaxWidthHeight = 90;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={`about ${mode}`}>
            <div className={`about__container ${mode}`}>
                <div className={`about__header ${mode}`}>
                    {mode.includes('desktop')
                        ? <div className='navigation__container'>
                            <div className="navigation__back">
                                <div className="backContainer button"
                                    onMouseEnter={() => setBackFocus(true)}
                                    onMouseLeave={() => setBackFocus(false)}
                                    onClick={() => onBackButtonClick()}
                                >
                                    <div className="backImgContainer imgContainer">
                                        <img loading="lazy"
                                            style={{
                                                height: `${backButtonMaxWidthHeight}%`,
                                                width: `${backButtonMaxWidthHeight}%`,
                                                marginLeft: backIsFocused ? 5 : 20
                                            }}
                                            src={Arrow}
                                            alt="left arrow"
                                        ></img>
                                    </div>
                                    <span className={'backText' + (backIsFocused ? ' focused' : '')}>Back</span>
                                </div>
                            </div>
                            <div className={`navigation ${mode}`}>
                                {pageOptions.map((item) => {
                                    return (
                                        <React.Fragment key={`logo-${item.name}`}>
                                            {item.name.toLowerCase() !== pageTitle && <div
                                                className="navigation__item"
                                            >
                                                {item.type === 'link'
                                                    ? <Link to={item.link}>{item.name}</Link>
                                                    : <HashLink to={item.link}>{item.name}</HashLink>
                                                }
                                            </div>
                                            }
                                        </React.Fragment>
                                    )
                                })}
                                <div className={`homeContainer ${mode}`}>
                                    <Link to="/">
                                        <div className='imgContainer button'
                                            onMouseEnter={() => setHomeFocus(true)}
                                            onMouseLeave={() => setHomeFocus(false)}
                                        >
                                            <img loading="lazy"
                                                style={{
                                                    height: `${homeButtonMaxWidthHeight}%`,
                                                    width: `${homeButtonMaxWidthHeight}%`
                                                }}
                                                src={HomeIcon}
                                                alt="home"
                                            ></img>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        : <div className='navigation__container'>
                            <div className={`navigation ${mode}`}>
                                {pageOptions.map((item) => {
                                    return (
                                        <React.Fragment key={`logo-${item.name}`}>
                                            {item.name.toLowerCase() !== pageTitle && <div
                                                className="navigation__item"
                                            >
                                                {item.type === 'link'
                                                    ? <Link to={item.link}>{item.name}</Link>
                                                    : <HashLink to={item.link}>{item.name}</HashLink>
                                                }
                                            </div>
                                            }
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                            <div className={`homeContainer ${mode}`}>
                                <Link to="/">
                                    <div className='imgContainer button'
                                        onMouseEnter={() => setHomeFocus(true)}
                                        onMouseLeave={() => setHomeFocus(false)}
                                    >
                                        <img loading="lazy"
                                            style={{
                                                height: `${homeButtonMaxWidthHeight}%`,
                                                width: `${homeButtonMaxWidthHeight}%`
                                            }}
                                            src={HomeIcon}
                                            alt="home"
                                        ></img>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    }
                    <div className="about__header__topContainer">
                        <div className="about__header__middle">
                            <div className="imgContainer">
                                <img loading="lazy"
                                    src={Header}
                                    alt="About Us"
                                ></img>
                            </div>
                        </div>
                    </div>
                    <div className='imgContainer'>
                        <img loading="lazy"
                            src={Divider}
                            alt="divider"
                        ></img>
                    </div>
                </div>
                <div className={`about__firstSection ${mode}`}>
                    <div className='imgContainer'>
                        <img loading="lazy"
                            src={mode.includes('mobile') ? BackgroundMobile : Background}
                            alt="background us"
                        ></img>
                    </div>
                    <div className='about__firstSection__textContainer container__largeMaxWidth'>
                        <div className='about__firstSection__left'>
                            <div>
                                <div className="bigText">
                                    <div>
                                        <div>Rubii</div>
                                        <div>Graphics</div>
                                    </div>
                                </div>
                                <div className='section__text'>
                                    <br />
                                    is a small, graphics-based business
                                    located in the center of Alabama, USA. Our specialty
                                    is providing you with personalized services in the
                                    following areas among others:
                                    <div className='about__firstSection__lists'>
                                        <div>
                                            {firstList.map(item => {
                                                return (
                                                    <div key={item}>
                                                        - {item}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div>
                                            {secondList.map(item => {
                                                return (
                                                    <div key={item}>
                                                        - {item}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    It's God first, and then you!
                                    We pride ourselves in delivering a quality experience for every customer.
                                </div>
                            </div>
                        </div>
                        {!mode.includes('mobile')
                            && <div className='about__firstSection__right'>
                                <div className='imgContainer'>
                                    <img loading="lazy"
                                        src={Medal}
                                        alt="Rubii Graphics Medal"
                                    ></img>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className='imgContainer' style={{ marginTop: 20 }}>
                    <img loading="lazy"
                        src={Divider}
                        alt="divider"
                    ></img>
                </div>
                <div className={`about__secondSection ${mode}`}>
                    <div className='about__secondSection__textContainer container__largeMaxWidth'>
                        {!mode.includes('mobile')
                            && <div className='about__secondSection__left'>
                                <div className='imgContainer'>
                                    <img loading="lazy"
                                        src={Profile}
                                        alt="profile"
                                    ></img>
                                </div>
                            </div>
                        }
                        <div className='about__secondSection__right'>
                            <div className="bigText">
                                <div>
                                    <div>Hello</div>
                                    <div>Everybody</div>
                                </div>
                            </div>
                            <span>
                                it's Rubii. Even as a young girl,
                                I had envisioned myself owning a business
                                that would provide useful services to people.
                                The original plan was to start a hair salon,
                                but as the years went on, the plan morphed
                                to graphic design. I had successfully helped
                                several relatives and acquaintances with their
                                website designs, and the number of clients
                                continued growing.
                                Soon, the business just kind of...happened!
                                Thank you for considering Rubii Graphics
                                for your graphics needs.
                                I guarantee that you will be as pleased
                                as my clients preceding you and I look
                                forward to doing business together!
                                <br /><br />
                                <div className='about__secondSection__bottom'>
                                    <div>
                                        Blessings,
                                        <div className='imgContainer sigContainer'>
                                            <img loading="lazy"
                                                src={Sig}
                                                alt="signature"
                                            ></img>
                                        </div>
                                        Rubii
                                    </div>
                                    {mode.includes('mobile')
                                        &&
                                        <React.Fragment>
                                            <div>
                                                <div className='imgContainer'>
                                                    <img loading="lazy"
                                                        src={Profile}
                                                        alt="profile"
                                                    ></img>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='imgContainer'>
                                                    <img loading="lazy"
                                                        src={Medal}
                                                        alt="Rubii Graphics Medal"
                                                    ></img>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
