import React, { useRef, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import Logo from "../../resources/headers/logo.webp";
import Dash from "../../resources/elements/dash.png";
import Divider from "../../resources/borders/divider.png"

import './LogoSection.scss'

const LogoSection = (props) => {
    const {
        pageOptions,
        services,
        socialMediaLinks,
        pageTitle,
        mode,
        handleMenuClick,
        menuOpen
    } = props;
    const menuRef = useRef(null);
    const hamburgerRef = useRef(null);

    useEffect(() => {
        const handleClickOutsideMenu = (e) => {
            if (menuRef.current && !menuRef.current.contains(e.target)) {
                if (hamburgerRef.current && !hamburgerRef.current.contains(e.target)) {
                    if (menuOpen) {
                        console.log('clicked outside');
                        handleMenuClick();
                    }
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutsideMenu);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideMenu);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div className={`logoSection ${mode}`}>
            <div className={`logoSection__top ${mode}`}>
                {mode.includes('mobile')
                    ?
                    <React.Fragment>
                        <div
                            className={"hamburger__container"
                                + (menuOpen ? " open" : "")}

                            onClick={() => handleMenuClick()}
                            ref={hamburgerRef}
                        >
                            <div id="hamburger">
                                <span></span><span></span><span></span>
                            </div>
                            <div id="cross">
                                <span></span><span></span>
                            </div>
                        </div>
                        <div className={'hamburger__collapsible' + (
                            menuOpen ? ' open' : ''
                        )}
                            ref={menuRef}
                        >
                            {pageOptions.map((item) => {
                                return (
                                    <React.Fragment key={`logo-${item.name}`}>
                                        {item.name.toLowerCase() !== pageTitle && <div
                                            className='hamburger__list__item'
                                        >
                                            {item.type === 'link'
                                                ? <Link to={item.link}>{item.name}</Link>
                                                : <HashLink smooth to={item.link}>{item.name}</HashLink>
                                            }
                                        </div>
                                        }
                                    </React.Fragment>
                                )
                            })}
                            <div className='socialMedia__links'>
                                {socialMediaLinks.map((item, index) => {
                                    return (
                                        <a
                                            key={`logo-${item.href}-${index}`}
                                            href={item.href}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {item.darkIcon}
                                        </a>
                                    )
                                })}
                            </div>
                        </div>
                    </React.Fragment>
                    : <React.Fragment>
                        <div className={`logoSection__top__leftSide ${mode}`}>
                            {pageOptions.map((item) => {
                                return (
                                    <React.Fragment key={`logo-${item.name}`}>
                                        {item.name.toLowerCase() !== pageTitle && <div
                                            className={`list__item ${mode}`}
                                        >
                                            {item.type === 'link'
                                                ? <Link to={item.link}>{item.name}</Link>
                                                : <HashLink smooth to={item.link}>{item.name}</HashLink>
                                            }
                                        </div>
                                        }
                                    </React.Fragment>
                                )
                            })}
                        </div>
                        <div className={`logoSection__top__rightSide ${mode}`}>
                            {socialMediaLinks.map((item, index) => {
                                return (
                                    <a
                                        key={`logo-${item.href}-${index}`}
                                        href={item.href}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {item.icon}
                                    </a>
                                )
                            })}
                        </div>
                    </React.Fragment>
                }
            </div>
            <div className={`logoSection__middle ${mode}`}>
                <div className={mode.includes('desktop')
                    ? 'container__smallMaxWidth'
                    : ''}>
                    <div className='imgContainer'>
                        <img
                            loading='lazy'
                            src={Logo}
                            alt="Rubii Graphics"
                        ></img>
                    </div>
                </div>
            </div>
            <div className={`logoSection__bottom ${mode}`}>
                {!mode.includes('mobile')
                    && <div className='container__largeMaxWidth'>
                        <div className='servicesList'>
                            {services.map((item, index) => {
                                return (
                                    <div
                                        key={item}
                                        className={`list__item ${mode}`}
                                    >
                                        <img loading="lazy"
                                            src={Dash}
                                            alt="dash"
                                            height={25}
                                        ></img>
                                        {item}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                }
                <div className='imgContainer'>
                    <img loading="lazy"
                        src={Divider}
                        alt="divider"
                    ></img>
                </div>
            </div>
        </div>
    )
}

export default LogoSection;