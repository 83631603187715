import React, { useState } from 'react';

import Modal from "@mui/material/Modal";
import Divider from '../../resources/borders/divider.png';
import Arrow from "../../resources/buttons/arrow.png";

import './SampleModal.scss';

const SampleModal = (props) => {
    const {
        show,
        mockups,
        website,
        onClose,
        alt,
        mode
    } = props;

    const [backIsFocused, setBackFocus] = useState(false);
    let backButtonMaxWidthHeight = mode.includes('desktop')
        ? 90 : mode.includes('tablet') ? 65 : 50;
    if (backIsFocused) {
        backButtonMaxWidthHeight = backButtonMaxWidthHeight * 1.1
    } else {
        backButtonMaxWidthHeight = mode.includes('desktop')
            ? 90 : mode.includes('tablet') ? 65 : 50;
    }

    return (
        <Modal
            open={show}
            style={{
                overflowY: 'auto',
                minHeight: '100vh'
            }}
        >
            <div className={`sampleModal__container ${mode}`}>
                <div className={`sampleModal__top ${mode}`}>
                    <div className={`sampleModal__top__left`}>
                        <div className='sampleModal__backContainer'>
                            <div className="sampleModal__backContainer button"
                                onMouseEnter={() => setBackFocus(true)}
                                onMouseLeave={() => setBackFocus(false)}
                                onClick={() => onClose()}
                            >
                                <div className="sampleModal__backImgContainer imgContainer">
                                    <img loading="lazy"
                                        style={{
                                            height: `${backButtonMaxWidthHeight}%`,
                                            width: `${backButtonMaxWidthHeight}%`,
                                            marginLeft: backIsFocused ? 5 : 20
                                        }}
                                        src={Arrow}
                                        alt="left arrow"
                                    ></img>
                                </div>
                                <span className={'sampleModal__backText' + (backIsFocused ? ' focused' : '')}>Back</span>
                            </div>
                        </div>
                    </div>
                    <div className={`sampleModal__top__middle`}>
                        <div className='imgContainer'>
                            <img loading="lazy"
                                src={mockups}
                                alt={`${alt} mockups`}
                            ></img>
                        </div>
                    </div>
                    <div></div>
                </div>
                <div className={`imgContainer sampleModal__divider ${mode}`}>
                    <img loading="lazy"
                        src={Divider}
                        alt='divider'>
                    </img>
                </div>
                <div className={`sampleModal__bottom ${mode}`}>
                    <div className='container__largeMaxWidth'>
                        <div className='imgContainer'>
                            <img
                                loading="lazy"
                                src={website}
                                alt={`${alt} website`}>
                            </img>
                        </div>
                    </div>
                </div>
                <div className={`imgContainer sampleModal__divider ${mode}`}
                    style={{ margin: 0 }}>
                    <img loading="lazy"
                        src={Divider}
                        alt='divider'>
                    </img>
                </div>
            </div>
        </Modal>
    )
}

export default SampleModal;