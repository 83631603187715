import React, { useState } from 'react';
import { importAll } from '../../utils';
import Modal from "../ImageModal";

import PackageDeals from "../../resources/services/logo/package-deals.png";
import Price from "../../resources/services/logo/price-per-logo.png";

import './Logos.scss';

const boxes = importAll(require.context('../../resources/services/logo/boxes', false, /\.(png|jpe?g|svg|webp)$/), 'logo');
const images = importAll(require.context('../../resources/services/logo/images', false, /\.(png|jpe?g|svg|webp)$/), 'logo');

const Logos = (props) => {
    const {
        mode
    } = props;
    const [boxIsFocused, setBoxFocus] = useState(-1);
    const [currImageIndex, setImageIndex] = useState(null);

    let boxMaxWidthHeight = 97;
    let expandBoxMaxWidthHeight = 100;

    const firstRow = boxes.slice(0, 2);
    const secondRow = boxes.slice(2, 4);
    const loneBox = boxes[4];
    const thirdRow = boxes.slice(5, 7);
    const fourthRow = boxes.slice(7)

    const onBoxClick = (img) => {
        if (img) {
            setImageIndex(img.index);
        }
    }

    return (
        <>
            <div className={`logos__content ${mode}`}>
                <div className={`logos__body whiteText  ${mode}` + (mode.includes('mobile')
                    ? '' : ' container__largeMaxWidth')}>
                    <div className={`logos__body__left ${mode}`}>
                        <div className='mainTitleText lgText centerAlign'>Pricing Information</div>
                        <div className='imgContainer logoPrice'>
                            <img loading="lazy"
                                src={Price}
                                alt="$80/logo"
                            ></img>
                        </div>
                        {mode.includes('mobile')
                            && <div className='boxes__container'>
                                <div className='logos__box__group'>
                                    <div className='logos__box__row'>
                                        {firstRow.map((image) => {
                                            return (
                                                <div key={image.src} className='imgContainer button'
                                                    onMouseEnter={() => setBoxFocus(image.src)}
                                                    onMouseLeave={() => setBoxFocus(-1)}
                                                    onClick={() => onBoxClick(image)}
                                                >
                                                    <img
                                                        src={image.src}
                                                        style={{
                                                            height: `100%`,
                                                            width: `100%`
                                                        }}
                                                        alt="logo example"
                                                    ></img>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className='logos__box__row'>
                                        {secondRow.map((image) => {
                                            return (
                                                <div key={image.src} className='imgContainer button'
                                                    onMouseEnter={() => setBoxFocus(image.src)}
                                                    onMouseLeave={() => setBoxFocus(-1)}
                                                    onClick={() => onBoxClick(image)}
                                                >
                                                    <img
                                                        src={image.src}
                                                        style={{
                                                            height: `100%`,
                                                            width: `100%`
                                                        }}
                                                        alt="logo example"
                                                    ></img>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className='logos__box'>
                                    <div className='imgContainer button'
                                        onMouseEnter={() => setBoxFocus(loneBox.src)}
                                        onMouseLeave={() => setBoxFocus(-1)}
                                        onClick={() => onBoxClick(loneBox)}
                                    >
                                        <img
                                            src={loneBox.src}
                                            alt="logo example"
                                            style={{
                                                height: `100%`,
                                                width: `100%`
                                            }}
                                        ></img>
                                    </div>
                                </div>
                                <div className='logos__box__group'>
                                    <div className='logos__box__row'>
                                        {thirdRow.map((image) => {
                                            return (
                                                <div key={image.src} className='imgContainer button'
                                                    onMouseEnter={() => setBoxFocus(image.src)}
                                                    onMouseLeave={() => setBoxFocus(-1)}
                                                    onClick={() => onBoxClick(image)}
                                                >
                                                    <img
                                                        src={image.src}
                                                        style={{
                                                            height: `100%`,
                                                            width: `100%`
                                                        }}
                                                        alt="logo example"
                                                    ></img>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className='logos__box__row'>
                                        {fourthRow.map((image) => {
                                            return (
                                                <div key={image.src} className='imgContainer button'
                                                    onMouseEnter={() => setBoxFocus(image.src)}
                                                    onMouseLeave={() => setBoxFocus(-1)}
                                                    onClick={() => onBoxClick(image)}
                                                >
                                                    <img
                                                        src={image.src}
                                                        style={{
                                                            height: `100%`,
                                                            width: `100%`
                                                        }}
                                                        alt="logo example"
                                                    ></img>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='imgContainer packageDeals__container'>
                            <img loading="lazy"
                                src={PackageDeals}
                                alt="package deals"
                            ></img>
                        </div>
                    </div>
                    {!mode.includes('mobile')
                        && <div className={`logos__body__right  ${mode}`}>
                            <div className='logos__box__group'>
                                <div className='logos__box__row'>
                                    {firstRow.map((image) => {
                                        return (
                                            <div key={image.src} className='imgContainer button'
                                                onMouseEnter={() => setBoxFocus(image.src)}
                                                onMouseLeave={() => setBoxFocus(-1)}
                                                onClick={() => onBoxClick(image)}
                                            >
                                                <img
                                                    src={image.src}
                                                    style={{
                                                        height: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`,
                                                        width: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`
                                                    }}
                                                    alt="logo example"
                                                ></img>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='logos__box__row'>
                                    {secondRow.map((image) => {
                                        return (
                                            <div key={image.src} className='imgContainer button'
                                                onMouseEnter={() => setBoxFocus(image.src)}
                                                onMouseLeave={() => setBoxFocus(-1)}
                                                onClick={() => onBoxClick(image)}
                                            >
                                                <img
                                                    src={image.src}
                                                    style={{
                                                        height: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`,
                                                        width: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`
                                                    }}
                                                    alt="logo example"
                                                ></img>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className='logos__box'>
                                <div className='imgContainer button'
                                    onMouseEnter={() => setBoxFocus(loneBox.src)}
                                    onMouseLeave={() => setBoxFocus(-1)}
                                    onClick={() => onBoxClick(loneBox)}
                                >
                                    <img
                                        src={loneBox.src}
                                        alt="logo example"
                                        style={{
                                            height: `${loneBox.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`,
                                            width: `${loneBox.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`
                                        }}
                                    ></img>
                                </div>
                            </div>
                            <div className='logos__box__group'>
                                <div className='logos__box__row'>
                                    {thirdRow.map((image) => {
                                        return (
                                            <div key={image.src} className='imgContainer button'
                                                onMouseEnter={() => setBoxFocus(image.src)}
                                                onMouseLeave={() => setBoxFocus(-1)}
                                                onClick={() => onBoxClick(image)}
                                            >
                                                <img
                                                    src={image.src}
                                                    style={{
                                                        height: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`,
                                                        width: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`
                                                    }}
                                                    alt="logo example"
                                                ></img>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='logos__box__row'>
                                    {fourthRow.map((image) => {
                                        return (
                                            <div key={image.src} className='imgContainer button'
                                                onMouseEnter={() => setBoxFocus(image.src)}
                                                onMouseLeave={() => setBoxFocus(-1)}
                                                onClick={() => onBoxClick(image)}
                                            >
                                                <img
                                                    src={image.src}
                                                    style={{
                                                        height: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`,
                                                        width: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`
                                                    }}
                                                    alt="logo example"
                                                ></img>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Modal
                show={currImageIndex !== null}
                images={images}
                currIndex={currImageIndex}
                onClose={() => setImageIndex(null)}
                mode={mode}
            />
        </>
    )
}

export default Logos;