import React, { useState } from 'react';

import SampleModal from "./SampleModal";

import PackageDeals from "../../resources/services/webdesign/package-deals.png";

import Thumbnail1 from "../../resources/services/webdesign/samples/sample-1/thumbnail.png";
import Thumbnail1Mobile from "../../resources/services/webdesign/samples/sample-1/thumbnail-mobile.png";
import Mockups1 from "../../resources/services/webdesign/samples/sample-1/mockups.png";
import Website1 from "../../resources/services/webdesign/samples/sample-1/website.png";

import Thumbnail2 from "../../resources/services/webdesign/samples/sample-2/thumbnail.png";
import Thumbnail2Mobile from "../../resources/services/webdesign/samples/sample-2/thumbnail-mobile.png";
import Mockups2 from "../../resources/services/webdesign/samples/sample-2/mockups.png";
import Website2 from "../../resources/services/webdesign/samples/sample-2/website.png";

import Thumbnail3 from "../../resources/services/webdesign/samples/sample-3/thumbnail.png";
import Thumbnail3Mobile from "../../resources/services/webdesign/samples/sample-3/thumbnail-mobile.png";
import Mockups3 from "../../resources/services/webdesign/samples/sample-3/mockups.png";
import Website3 from "../../resources/services/webdesign/samples/sample-3/website.png";

import './WebsiteDesign.scss';

const WebsiteDesign = (props) => {
    const {
        mode
    } = props;
    const [sampleIsFocused, setSampleFocus] = useState(-1);
    const [currSample, setSample] = useState(null);

    const samples = [
        {
            thumbnail: Thumbnail1,
            thumbnailMobile: Thumbnail1Mobile,
            sampleWebsite: Website1,
            mockups: Mockups1,
            alt: 'sample website 1'
        },
        {
            thumbnail: Thumbnail2,
            thumbnailMobile: Thumbnail2Mobile,
            sampleWebsite: Website2,
            mockups: Mockups2,
            alt: 'sample website 2'
        },
        {
            thumbnail: Thumbnail3,
            thumbnailMobile: Thumbnail3Mobile,
            sampleWebsite: Website3,
            mockups: Mockups3,
            alt: 'sample website 3'
        }
    ]

    return (
        <>
            <div className={`websiteDesign__content ${mode}`}>
                <div className={`websiteDesign__body whiteText ${mode}` + (
                    !mode.includes('mobile') ? ' container__largeMaxWidth' : ''
                )}>
                    <div className={`websiteDesign__body__left ${mode}`}>
                        <div className={`mainTitleText lgText centerAlign`}>Pricing Information</div>
                        <div className={mode.includes('desktop') ? 'centerAlign' : 'marginText'}>
                            <div className='titleText mText'>Website Design<span className='pinkText bodyText'>&nbsp;from $700</span></div>
                            <div className='bodyText sText'>1 responsive website design</div>
                        </div>
                        <div className={mode.includes('desktop') ? 'centerAlign' : 'marginText'}>
                            <div className='titleText mText'>Website Component<span className='pinkText bodyText'>&nbsp;from $40</span></div>
                            <div className='bodyText sText'>1 individual website design</div>
                        </div>
                        <div className='pinkText xsText bodyText'>Looking for a developer? Need a domain? Ask for our recommendations!</div>
                        {mode.includes('mobile')
                            && <div className='samples__container'>
                                {samples.map((item, index) => {
                                    let sampleMaxWidthHeight = 100;

                                    return (
                                        <div
                                            key={index}
                                            className='websiteDesign__sampleContainer'
                                        >
                                            <div className='imgContainer button'
                                                onMouseEnter={() => setSampleFocus(index)}
                                                onMouseLeave={() => setSampleFocus(-1)}
                                                onClick={() => {
                                                    setSample(item)
                                                }}
                                            >
                                                <img loading="lazy"
                                                    style={{
                                                        height: `${sampleMaxWidthHeight}%`,
                                                        width: `${sampleMaxWidthHeight}%`
                                                    }}
                                                    src={mode.includes('mobile') ? item.thumbnailMobile : item.thumbnail}
                                                    alt={item.alt}
                                                ></img>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        <div className='imgContainer packageDeals__container'>
                            <img loading="lazy"
                                src={PackageDeals}
                                alt="package deals"
                            ></img>
                        </div>
                    </div>
                    {!mode.includes('mobile')
                        && <div className={`websiteDesign__body__right ${mode}`}>
                            {samples.map((item, index) => {
                                let sampleMaxWidthHeight = 90;

                                if (index === sampleIsFocused) {
                                    sampleMaxWidthHeight = sampleMaxWidthHeight * 1.1
                                }
                                return (
                                    <div
                                        key={index}
                                        className='websiteDesign__sampleContainer'
                                    >
                                        <div className='imgContainer button'
                                            onMouseEnter={() => setSampleFocus(index)}
                                            onMouseLeave={() => setSampleFocus(-1)}
                                            onClick={() => {
                                                setSample(item)
                                            }}
                                        >
                                            <img loading="lazy"
                                                style={{
                                                    height: `${sampleMaxWidthHeight}%`,
                                                    width: `${sampleMaxWidthHeight}%`
                                                }}
                                                src={item.thumbnail}
                                                alt={item.alt}
                                            ></img>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
            </div>
            <SampleModal
                show={currSample !== null}
                mockups={currSample?.mockups}
                website={currSample?.sampleWebsite}
                alt={currSample?.alt}
                onClose={() => setSample(null)}
                mode={mode}
            />
        </>
    )
}

export default WebsiteDesign;