import React from 'react';

import Modal from "@mui/material/Modal";

import './NotifModal.scss';

const NotifModal = (props) => {
    const {
        headerText,
        bodyText,
        show,
        onModalClose
    } = props;

    return (
        <Modal
            open={show}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div className='notifModal__container'>
                <div>
                    <div className='notifModal__content__container'>
                        <div className="notifModal__header">
                            {headerText}
                        </div>
                        <div className="notifModal__body">
                            {bodyText}
                        </div>
                        <div className="notifModal__footer">
                            <button
                                className='notifModal__button'
                                onClick={() => onModalClose()}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default NotifModal;