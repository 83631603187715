import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import ServicesGraphic from "../../resources/graphics/services-graphic.webp";
import WebsiteDesignService from "../../resources/home services/service1.png";
import WebsiteDesignServiceMobile from "../../resources/home services/service1-mobile.webp";
import LogosService from "../../resources/home services/service2.png";
import LogosServiceMobile from "../../resources/home services/service2-mobile.webp";
import MarketingDesignService from "../../resources/home services/service3.png";
import MarketingDesignServiceMobile from "../../resources/home services/service3-mobile.webp";
import CharactersPropsService from "../../resources/home services/service4.png";
import CharactersPropsServiceMobile from "../../resources/home services/service4-mobile.webp";
import IllustrationsService from "../../resources/home services/service5.png";
import IllustrationsServiceMobile from "../../resources/home services/service5-mobile.webp";

import './ServicesSection.scss';

const ServicesSection = (props) => {
    const {
        mode
    } = props;
    const [isFocused, setFocus] = useState(null);

    let servicesMarginTop = -50;
    let bottomServiceMarginTop = -35;
    if (mode.includes('tablet')) {
        servicesMarginTop = -40;
        bottomServiceMarginTop = -20;
    } else if (mode.includes('mobile')) {
        servicesMarginTop = 0;
        bottomServiceMarginTop = 0;
    }

    const services = [
        {
            src: mode.includes('mobile')
                ? WebsiteDesignServiceMobile : WebsiteDesignService,
            alt: "website design services",
            maxHeight: mode.includes('mobile')
                ? "83%" : "80%",
            maxWidth: mode.includes('mobile')
                ? "83%" : "80%",
            link: "/services/webdesign"
        }, {
            src: mode.includes('mobile')
                ? LogosServiceMobile : LogosService,
            alt: "logos services",
            maxHeight: "100%",
            maxWidth: "100%",
            link: "/services/logo"
        }, {
            src: mode.includes('mobile')
                ? MarketingDesignServiceMobile : MarketingDesignService,
            alt: "marketing design services",
            maxHeight: "95%",
            maxWidth: "95%",
            link: "/services/marketdesign"
        }, {
            src: mode.includes('mobile')
                ? CharactersPropsServiceMobile : CharactersPropsService,
            alt: "characters+props services",
            maxHeight: mode.includes('mobile')
                ? "70%" : "75%",
            maxWidth: mode.includes('mobile')
                ? "70%" : "75%",
            link: "/services/charactersprops"
        }, {
            src: mode.includes('mobile')
                ? IllustrationsServiceMobile : IllustrationsService,
            alt: "illustration services",
            maxHeight: mode.includes('mobile')
                ? "42%" : "48%",
            maxWidth: mode.includes('mobile')
                ? "42%" : "48%",
            link: "/services/illustration"
        }
    ]

    return (
        <div className={`servicesSection ${mode}`}>
            <div className={`servicesSection__leftSide ${mode}`}>
                <div>
                    <div className='imgContainer'>
                        <img
                            src={ServicesGraphic}
                            alt="services"
                        ></img>
                    </div>
                </div>
            </div>
            <div className={`servicesSection__rightSide ${mode}`}>
                <div className={mode.includes('mobile') ? '' : 'container__smallMaxWidth'}>
                    {services.map((item, index) => {
                        let maxHeight = item.maxHeight;
                        let maxWidth = item.maxWidth;

                        // When section is on focus, increase slightly the width and height
                        if (isFocused === item.alt) {
                            let parsedFloat = parseFloat(maxWidth)
                            let newWidth = `${parsedFloat * 1.1}%`
                            maxWidth = newWidth;
                            maxHeight = newWidth

                        }

                        return (
                            <Link to={item.link} key={item.alt}
                                style={{
                                    position: "relative",
                                    zIndex: services.length - index
                                }}>
                                <div
                                    onMouseEnter={() => setFocus(item.alt)}
                                    onMouseLeave={() => setFocus(null)}
                                    className='imgContainer'
                                    style={{
                                        marginTop: (index === 0)
                                            ? 0
                                            : (index === services.length - 1)
                                                ? bottomServiceMarginTop
                                                : servicesMarginTop
                                    }}
                                >
                                    <img
                                        src={item.src}
                                        alt={item.alt}
                                        style={{
                                            maxHeight: maxHeight,
                                            maxWidth: maxHeight
                                        }}
                                    ></img>
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default ServicesSection;