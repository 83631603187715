import React, { useState } from 'react';
import { importAll } from '../../utils';
import Modal from "../ImageModal";

import PackageDeals from "../../resources/services/marketdesign/package-deals.png";

import './MarketingDesign.scss';

const boxes = importAll(require.context('../../resources/services/marketdesign/boxes', false, /\.(webp)$/), 'marketdesign');
const images = importAll(require.context('../../resources/services/marketdesign/images', false, /\.(webp)$/), 'marketdesign');

const MarketingDesign = (props) => {
    const {
        mode
    } = props;
    const servicesAndPrice = [{
        service: 'Social media banner',
        price: 100
    }, {
        service: 'Social media profile picture',
        price: 60
    }, {
        service: 'Social media post design',
        price: 100
    }, {
        service: 'Product label design',
        price: 100
    }, {
        service: 'QR Code',
        price: 80
    }
    ];

    const [boxIsFocused, setBoxFocus] = useState(-1);
    const [currImageIndex, setImageIndex] = useState(null);

    let boxMaxWidthHeight = 97;
    let expandBoxMaxWidthHeight = 100;

    let loneBox1 = boxes[0];
    let firstRow = boxes.slice(1, 3);
    let secondRow = boxes.slice(3, 5);
    let thirdRow = boxes.slice(5, 7);
    let loneBox2 = boxes[7];

    if (mode.includes('mobile')) {
        firstRow = [boxes[0]].concat([boxes[3]], boxes.slice(5, 7));
        secondRow = boxes.slice(1, 3).concat([boxes[4]], [boxes[7]]);
    }

    const onBoxClick = (img) => {
        if (img) {
            setImageIndex(img.index);
        }
    }

    const ServiceRow = ({ service, price }) => {
        return (
            <div className='centerAlign'>
                <div className='titleText mText'>{service}</div>
                <div className='bodyText sText'>${price}</div>
            </div>
        )
    };

    return (
        <>
            <div className={`marketingDesign__content ${mode}`}>
                <div className={`marketingDesign__body whiteText ${mode}` + (mode.includes('mobile')
                    ? '' : ' container__largeMaxWidth')}>
                    <div className={`marketingDesign__body__left ${mode}`}>
                        <div className='mainTitleText lgText centerAlign'>Pricing Information</div>
                        {servicesAndPrice.map((service) =>
                            <React.Fragment key={service.service}>
                                <ServiceRow service={service.service} price={service.price} />
                            </React.Fragment>
                        )}
                        {mode.includes('mobile')
                            &&
                            <div className='marketingDesign__box__group'>
                                <div className='marketingDesign__box__row'>
                                    {firstRow.map((image) => {
                                        return (
                                            <div key={image.src} className='imgContainer button'
                                                onMouseEnter={() => setBoxFocus(image.src)}
                                                onMouseLeave={() => setBoxFocus(-1)}
                                                onClick={() => onBoxClick(image)}
                                            >
                                                <img
                                                    src={image.src}
                                                    style={{
                                                        height: `100%`,
                                                        width: `100%`
                                                    }}
                                                    alt="logo example"
                                                ></img>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='marketingDesign__box__row'>
                                    {secondRow.map((image) => {
                                        return (
                                            <div key={image.src} className='imgContainer button'
                                                onMouseEnter={() => setBoxFocus(image.src)}
                                                onMouseLeave={() => setBoxFocus(-1)}
                                                onClick={() => onBoxClick(image)}
                                            >
                                                <img
                                                    src={image.src}
                                                    style={{
                                                        height: `100%`,
                                                        width: `100%`
                                                    }}
                                                    alt="logo example"
                                                ></img>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        }
                        <div className='imgContainer packageDeals__container'>
                            <img loading="lazy"
                                src={PackageDeals}
                                alt="package deals"
                            ></img>
                        </div>
                    </div>
                    {!mode.includes('mobile')
                        && <div className={`marketingDesign__body__right ${mode}`}>
                            <div className='marketingDesign__box'>
                                <div className='imgContainer button'
                                    onMouseEnter={() => setBoxFocus(loneBox1.src)}
                                    onMouseLeave={() => setBoxFocus(-1)}
                                    onClick={() => onBoxClick(loneBox1)}
                                >
                                    <img
                                        src={loneBox1.src}
                                        alt="logo example"
                                        style={{
                                            height: `${loneBox1.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`,
                                            width: `${loneBox1.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`
                                        }}
                                    ></img>
                                </div>
                            </div>
                            <div className='marketingDesign__box__group'>
                                <div className='marketingDesign__box__row'>
                                    {firstRow.map((image) => {
                                        return (
                                            <div key={image.src} className='imgContainer button'
                                                onMouseEnter={() => setBoxFocus(image.src)}
                                                onMouseLeave={() => setBoxFocus(-1)}
                                                onClick={() => onBoxClick(image)}
                                            >
                                                <img
                                                    src={image.src}
                                                    style={{
                                                        height: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`,
                                                        width: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`
                                                    }}
                                                    alt="logo example"
                                                ></img>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='marketingDesign__box__row'>
                                    {secondRow.map((image) => {
                                        return (
                                            <div key={image.src} className='imgContainer button'
                                                onMouseEnter={() => setBoxFocus(image.src)}
                                                onMouseLeave={() => setBoxFocus(-1)}
                                                onClick={() => onBoxClick(image)}
                                            >
                                                <img
                                                    src={image.src}
                                                    style={{
                                                        height: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`,
                                                        width: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`
                                                    }}
                                                    alt="logo example"
                                                ></img>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='marketingDesign__box__row'>
                                    {thirdRow.map((image) => {
                                        return (
                                            <div key={image.src} className='imgContainer button'
                                                onMouseEnter={() => setBoxFocus(image.src)}
                                                onMouseLeave={() => setBoxFocus(-1)}
                                                onClick={() => onBoxClick(image)}
                                            >
                                                <img
                                                    src={image.src}
                                                    style={{
                                                        height: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`,
                                                        width: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`
                                                    }}
                                                    alt="logo example"
                                                ></img>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className='marketingDesign__box'>
                                <div className='imgContainer button'
                                    onMouseEnter={() => setBoxFocus(loneBox2.src)}
                                    onMouseLeave={() => setBoxFocus(-1)}
                                    onClick={() => onBoxClick(loneBox2)}
                                >
                                    <img
                                        src={loneBox2.src}
                                        alt="logo example"
                                        style={{
                                            height: `${loneBox2.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`,
                                            width: `${loneBox2.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`
                                        }}
                                    ></img>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Modal
                show={currImageIndex !== null}
                images={images}
                currIndex={currImageIndex}
                onClose={() => setImageIndex(null)}
                mode={mode}
            />
        </>
    )
}

export default MarketingDesign;