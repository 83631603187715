import React, { useEffect, useState } from 'react';

import { Link, useParams } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

import WebsiteDesign from './WebsiteDesignComponents/WebsiteDesign';
import Logos from './LogosComponents/Logos';
import MarketingDesign from './MarketingDesignComponent/MarketingDesign';
import CharactersAndProps from './CharactersAndPropsComponents/CharactersAndProps';
import Illustrtaions from './IllustrationsComponents/Illustrations';

import Divider from "../resources/borders/divider.png";
import HomeIcon from "../resources/icons/home-icon.png";

import LogoHeader from "../resources/headers/logos-header.webp";
import WebDesignHeader from "../resources/headers/website-design-header.webp";
import MarketDesignHeader from "../resources/headers/marketing-design-header.webp";
import CharactersAndPropsHeader from "../resources/headers/characters-and-props-header.webp";
import IllustrationsHeader from '../resources/headers/illustrations-header.webp';

import './Service.scss'

const Service = (props) => {
    const {
        pageOptions,
        mode
    } = props;

    let { id } = useParams();
    const [homeIsFocused, setHomeFocus] = useState(false);

    let homeButtonMaxWidthHeight = 90;
    if (homeIsFocused) {
        homeButtonMaxWidthHeight = homeButtonMaxWidthHeight * 1.1
    } else {
        homeButtonMaxWidthHeight = 90;
    }

    let header = {
        logo: null,
        maxWidth: 350
    }

    if (id === 'webdesign') {
        header = {
            logo: WebDesignHeader,
            maxWidth: 650
        }
    } else if (id === 'logo') {
        header = {
            logo: LogoHeader,
            maxWidth: mode.includes('mobile') ? 200 : 350
        }
    } else if (id === 'marketdesign') {
        header = {
            logo: MarketDesignHeader,
            maxWidth: 900
        }
    } else if (id === 'charactersprops') {
        header = {
            logo: CharactersAndPropsHeader,
            maxWidth: mode.includes('mobile') ? 300 : 550
        }
    } else if (id === 'illustration') {
        header = {
            logo: IllustrationsHeader,
            maxWidth: 650
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const Header = ({ header, maxWidth }) => {
        return (
            <div className='service__header'>
                <div className='navigation__container'>
                    <div className={`navigation ${mode}`}>
                        {pageOptions.map((item) => {
                            return (
                                <React.Fragment key={`logo-${item.name}`}>
                                    <div
                                        className="navigation__item"
                                    >
                                        {item.type === 'link'
                                            ? <Link to={item.link}>{item.name}</Link>
                                            : <HashLink to={item.link}>{item.name}</HashLink>
                                        }
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </div>
                    <div className={`homeContainer ${mode}`}>
                        <Link to="/">
                            <div className='imgContainer button'
                                onMouseEnter={() => setHomeFocus(true)}
                                onMouseLeave={() => setHomeFocus(false)}
                            >
                                <img loading="lazy"
                                    style={{
                                        height: `${homeButtonMaxWidthHeight}%`,
                                        width: `${homeButtonMaxWidthHeight}%`
                                    }}
                                    src={HomeIcon}
                                    alt="home"
                                ></img>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className='service__header__topContainer'>
                    <div className='service__header__middle'>
                        <div
                            className="imgContainer"
                            style={{
                                maxWidth: maxWidth,
                                width: '70vw',
                                minWidth: 100
                            }}
                        >
                            <img loading="lazy"
                                src={header}
                                alt="Website Design"
                            ></img>
                        </div>
                    </div>
                </div>
                <div className='imgContainer'>
                    <img loading="lazy"
                        src={Divider}
                        alt="divider"
                    ></img>
                </div>
            </div>
        )
    }

    return (
        <div className='service'>
            {header && <Header header={header.logo} maxWidth={header.maxWidth} />}
            {id === 'webdesign' && <WebsiteDesign mode={mode} />}
            {id === 'logo' && <Logos mode={mode} />}
            {id === 'marketdesign' && <MarketingDesign mode={mode} />}
            {id === 'charactersprops' && <CharactersAndProps mode={mode} />}
            {id === 'illustration' && <Illustrtaions mode={mode} />}
        </div>
    )
}

export default Service;