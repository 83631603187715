import React, { useRef, useState } from 'react';

import GabIcon from "../resources/icons/gab-icon.svg";
import DarkGabIcon from "../resources/icons/gab-icon-black.svg";
import FacebookIcon from "../resources/icons/facebook-icon.svg";
import DarkFacebookIcon from "../resources/icons/facebook-icon-black.svg";
import InstagramIcon from "../resources/icons/instagram-icon.svg";
import DarkInstagramIcon from "../resources/icons/instagram-icon-black.svg";
import XIcon from "../resources/icons/x-icon.svg";
import DarkXIcon from "../resources/icons/x-icon-black.svg";

import LogoSection from './LogoSectionComponents/LogoSection';
import WelcomeSection from './WelcomeSectionComponents/WelcomeSection';
import ServicesSection from './ServicesSectionComponents/ServicesSection';
import ReasonsSection from './ReasonsSectionComponents/ReasonsSections';
import Footer from './FooterComponents/Footer';

import './Home.scss';

const Home = (props) => {
    const pageTitle = "home";
    const {
        pageOptions,
        mode
    } = props;
    const logoRef = useRef(null);
    const welcomeRef = useRef(null);
    const servicesRef = useRef(null);
    const reasonsRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);

    const services = [
        'Website Design',
        'Logos',
        'Marketing Design',
        'Characters + Props',
        'Illustrations'
    ];

    const socialMediaLinks = [
        {
            href: "https://gab.com/RubiiGraphics",
            icon: <img loading="lazy"
                className='socialMedia__icon'
                src={GabIcon}
                alt="gab"
            ></img>,
            darkIcon: <img loading="lazy"
                className='socialMedia__icon'
                src={DarkGabIcon}
                alt="gab"
            ></img>
        },
        {
            href: "https://www.facebook.com/profile.php?id=100095292976390",
            icon: <img loading="lazy"
                className='socialMedia__icon'
                src={FacebookIcon}
                alt="facebook"
            ></img>,
            darkIcon: <img loading="lazy"
                className='socialMedia__icon'
                src={DarkFacebookIcon}
                alt="facebook"
            ></img>
        },
        {
            href: "https://instagram.com/rubiigraphics",
            icon: <img loading="lazy"
                className='socialMedia__icon'
                src={InstagramIcon}
                alt="instagram"
            ></img>,
            darkIcon: <img loading="lazy"
                className='socialMedia__icon'
                src={DarkInstagramIcon}
                alt="instagram"
            ></img>
        },
        {
            href: "https://twitter.com/RubiiGraphics",
            icon: <img loading="lazy"
                className='socialMedia__icon'
                style={{ margin: 0 }}
                src={XIcon}
                alt="x/twitter"
            ></img>,
            darkIcon: <img loading="lazy"
                className='socialMedia__icon'
                style={{ margin: 0 }}
                src={DarkXIcon}
                alt="x/twitter"
            ></img>
        }
    ];

    const reasonsToHire = [
        'Affordable Price',
        'Timely Response',
        'Personable Experience',
        'Personalized Services'
    ];

    const scrollToTop = () => {
        if (window) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    };

    const handleMenuClick = () => {
        setMenuOpen(!menuOpen)
    };

    return (
        <div className="home">
            <span ref={logoRef} id="header">
                <LogoSection
                    pageOptions={pageOptions}
                    services={services}
                    socialMediaLinks={socialMediaLinks}
                    pageTitle={pageTitle}
                    mode={mode}
                    handleMenuClick={handleMenuClick}
                    menuOpen={menuOpen}
                />
            </span>
            <span ref={welcomeRef} id="welcome">
                <WelcomeSection
                    mode={mode}
                />
            </span>
            <span ref={servicesRef} id="services">
                <ServicesSection
                    mode={mode}
                />
            </span>
            <span ref={reasonsRef} id="reasons">
                <ReasonsSection
                    reasonsToHire={reasonsToHire}
                    mode={mode}
                />
            </span>
            <Footer
                pageOptions={pageOptions}
                socialMediaLinks={socialMediaLinks}
                scrollToTop={scrollToTop}
                pageTitle={pageTitle}
                mode={mode}
            />
        </div>
    )
}

export default Home;