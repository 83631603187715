import React, { useState, useRef } from 'react';

import Modal from "../ImageModal";

import PackageDealsCharacters from '../../resources/services/charactersprops/package-deals-characters.png';
import PackageDealsProps from '../../resources/services/charactersprops/package-deals-props.png';
import Divider from '../../resources/borders/divider-2.png';
import UpButton from "../../resources/buttons/arrow.png";

import './CharactersAndProps.scss';

function importAll(r, padding = 0) {
    let result = [];
    let array = r.keys().map(r);

    array.sort(function (a, b) {
        let a_str = (a.split('/')[3]).split('.')[0];
        let a_chars = a_str.slice(0, a_str.search(/\d/));
        let a_number = Number(a_str.replace(a_chars, ''))

        let b_str = (b.split('/')[3]).split('.')[0];
        let b_chars = b_str.slice(0, b_str.search(/\d/));
        let b_number = Number(b_str.replace(b_chars, ''))

        if (a_number < b_number) return -1;
        if (a_number > b_number) return 1;
        return 0
    })

    for (let i = 0; i < array.length; i++) {
        result.push({
            src: array[i],
            index: i + padding
        })
    }

    return result;
}
const charactersBoxes = importAll(require.context('../../resources/services/charactersprops/characters/boxes', false, /\.(webp)$/));
const propsBoxes = importAll(require.context('../../resources/services/charactersprops/props/boxes', false, /\.(webp)$/), 5);
const images = importAll(require.context('../../resources/services/charactersprops/images', false, /\.(webp)$/));

const CharactersAndProps = (props) => {
    const {
        mode
    } = props;
    const charactersRef = useRef(null);
    const propsRef = useRef(null);

    const [backToTopButtonIsFocused, setBackToTopFocus] = useState(false);
    const [boxIsFocused, setBoxFocus] = useState(-1);
    const [currImageIndex, setImageIndex] = useState(null);

    let backToTopButtonMaxWidthHeight = 50;
    if (backToTopButtonIsFocused) {
        backToTopButtonMaxWidthHeight = backToTopButtonMaxWidthHeight * 1.5
    } else {
        backToTopButtonMaxWidthHeight = 50;
    }

    let boxMaxWidthHeight = 97;
    let expandBoxMaxWidthHeight = 100;

    let loneBox1 = propsBoxes[0]
    let propsFirstRow = propsBoxes.slice(1, 3);
    let propsSecondRow = propsBoxes.slice(3, 5);
    let loneBox2 = propsBoxes[propsBoxes.length - 1];

    /** Only for mobile version */
    let charactFirstColumn = charactersBoxes.slice(0, 2);
    let charactLoneBox = charactersBoxes[2];
    let charactSecondColumn = charactersBoxes.slice(3, 5);

    if (mode.includes('mobile')) {
        loneBox1 = propsBoxes[0]
        propsFirstRow = [propsBoxes[1]].concat([propsBoxes[3]]);
        propsSecondRow = [propsBoxes[2]].concat([propsBoxes[4]]);
        loneBox2 = propsBoxes[propsBoxes.length - 1];
    }

    const handleScroll = (ref) => {
        window.scrollTo({
            top: ref.offsetTop + 200,
            left: 0,
            behavior: "smooth",
        });
    };

    const onBoxClick = (img) => {
        if (img) {
            setImageIndex(img.index);
        }
    };

    const scrollToTop = () => {
        if (window) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    };

    return (
        <>
            <div className={`charactersAndProps__content ${mode}`}>
                <div className={`charactersAndProps__nav__container ${mode}`}>
                    <div className={'charactersAndProps__nav whiteText' + (mode.includes('mobile')
                        ? '' : ' container__largeMaxWidth')}>
                        <div
                            onClick={() => handleScroll(charactersRef.current)}
                            className='titleText underlinedText button'
                        >
                            Characters
                        </div>
                        <div
                            onClick={() => handleScroll(propsRef.current)}
                            className='titleText underlinedText button'
                        >
                            Props
                        </div>
                    </div>
                </div>
                {/* CHARACTERS SECTION */}
                <div
                    className={`whiteText charactersSection ${mode} `
                        + (mode.includes('desktop')
                            ? 'container__largeMaxWidth' : '')
                    }
                >
                    <div className='imgContainer nav__divider'>
                        <img loading="lazy"
                            src={Divider}
                            alt="divider"
                        ></img>
                    </div>
                    <div className={`charactersAndProps__body ${mode}`}
                        ref={charactersRef}
                    >
                        <div className={`charactersAndProps__textContent ${mode}`}>
                            <div>
                                <div className='mainTitleText lgText centerAlign'>
                                    Character Pricing Information
                                </div>
                                <div className='centerAlign'>
                                    <div className='titleText mText'>Character Illustration</div>
                                    <div className='bodyText sText'>$160</div>
                                </div>
                                <div className='centerAlign'>
                                    <div className='titleText mText'>Character Design</div>
                                    <div className='bodyText sText'>$160</div>
                                </div>
                                <div className='centerAlign'>
                                    <div className='titleText mText'>Headshot Illustration</div>
                                    <div className='bodyText sText'>$100</div>
                                </div>
                                <div className='centerAlign'>
                                    <div className='titleText mText'>Headshot Design</div>
                                    <div className='bodyText sText'>$100</div>
                                </div>
                                {mode.includes('mobile')
                                    && <div className='boxes__container'>
                                        <div className='charactersAndProps_box_column'>
                                            {charactFirstColumn.map((image) => {
                                                return (
                                                    <div key={image.src} className='imgContainer button'
                                                        onMouseEnter={() => setBoxFocus(image.src)}
                                                        onMouseLeave={() => setBoxFocus(-1)}
                                                        onClick={() => onBoxClick(image)}
                                                    >
                                                        <img
                                                            src={image.src}
                                                            style={{
                                                                height: `100%`,
                                                                width: `100%`
                                                            }}
                                                            alt="props example"
                                                        ></img>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className='charactersAndProps__box'>
                                            <div className='imgContainer button'
                                                onMouseEnter={() => setBoxFocus(charactLoneBox.src)}
                                                onMouseLeave={() => setBoxFocus(-1)}
                                                onClick={() => onBoxClick(charactLoneBox)}
                                            >
                                                <img
                                                    src={charactLoneBox.src}
                                                    alt="props example"
                                                    style={{
                                                        height: `100%`,
                                                        width: `100%`
                                                    }}
                                                ></img>
                                            </div>
                                        </div>
                                        <div className='charactersAndProps_box_column'>
                                            {charactSecondColumn.map((image) => {
                                                return (
                                                    <div key={image.src} className='imgContainer button'
                                                        onMouseEnter={() => setBoxFocus(image.src)}
                                                        onMouseLeave={() => setBoxFocus(-1)}
                                                        onClick={() => onBoxClick(image)}
                                                    >
                                                        <img
                                                            src={image.src}
                                                            style={{
                                                                height: `100%`,
                                                                width: `100%`
                                                            }}
                                                            alt="props example"
                                                        ></img>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                                <div className='imgContainer packageDeals__container'>
                                    <img loading="lazy"
                                        src={PackageDealsCharacters}
                                        alt="package deals"
                                    ></img>
                                </div>
                            </div>
                        </div>
                        {!mode.includes('mobile')
                            && <div className={`charactersAndProps__imagesContent ${mode}`}>
                                {charactersBoxes.map((image) => {
                                    return (
                                        <div key={image.src} className='imgContainer button'
                                            onMouseEnter={() => setBoxFocus(image.src)}
                                            onMouseLeave={() => setBoxFocus(-1)}
                                            onClick={() => onBoxClick(image)}
                                        >
                                            <img
                                                src={image.src}
                                                style={{
                                                    height: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`,
                                                    width: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`
                                                }}
                                                alt="character example"
                                            ></img>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                </div>
                {/* PROPS SECTION */}
                <div
                    className={`whiteText propsSection ${mode} `
                        + (mode.includes('desktop')
                            ? 'container__largeMaxWidth' : '')
                    }
                >
                    <div className='imgContainer nav__divider'>
                        <img loading="lazy"
                            src={Divider}
                            alt="divider"
                        ></img>
                    </div>
                    <div className={`charactersAndProps__body ${mode}`}
                        ref={propsRef}
                    >
                        <div className={`charactersAndProps__textContent ${mode}`}>
                            <div>
                                <div className='mainTitleText lgText centerAlign'>
                                    Prop
                                    {mode.includes('mobile') ? ' ' : <br />}
                                    Pricing Information
                                </div>
                                <div className='centerAlign'>
                                    <div className='titleText mText'>Prop Illustration</div>
                                    <div className='bodyText sText'>$80</div>
                                </div>
                                <div className='centerAlign'>
                                    <div className='titleText mText'>Prop Design</div>
                                    <div className='bodyText sText'>$80</div>
                                </div>
                                {mode.includes('mobile')
                                    && <div className='boxes__container'>
                                        <div className='charactersAndProps__box__group'>
                                            <div className='charactersAndProps__box'>
                                                <div className='imgContainer button'
                                                    onMouseEnter={() => setBoxFocus(loneBox1.src)}
                                                    onMouseLeave={() => setBoxFocus(-1)}
                                                    onClick={() => onBoxClick(loneBox1)}
                                                >
                                                    <img
                                                        src={loneBox1.src}
                                                        alt="props example"
                                                        style={{
                                                            height: `100%`,
                                                            width: `100%`
                                                        }}
                                                    ></img>
                                                </div>
                                            </div>

                                            <div className='charactersAndProps_box_row'>
                                                {propsFirstRow.map((image) => {
                                                    return (
                                                        <div key={image.src} className='imgContainer button'
                                                            onMouseEnter={() => setBoxFocus(image.src)}
                                                            onMouseLeave={() => setBoxFocus(-1)}
                                                            onClick={() => onBoxClick(image)}
                                                        >
                                                            <img
                                                                src={image.src}
                                                                style={{
                                                                    height: `100%`,
                                                                    width: `100%`
                                                                }}
                                                                alt="props example"
                                                            ></img>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className='charactersAndProps__box__group'>
                                            <div className='charactersAndProps_box_row'>
                                                {propsSecondRow.map((image) => {
                                                    return (
                                                        <div key={image.src} className='imgContainer button'
                                                            onMouseEnter={() => setBoxFocus(image.src)}
                                                            onMouseLeave={() => setBoxFocus(-1)}
                                                            onClick={() => onBoxClick(image)}
                                                        >
                                                            <img
                                                                src={image.src}
                                                                style={{
                                                                    height: `100%`,
                                                                    width: `100%`
                                                                }}
                                                                alt="props example"
                                                            ></img>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className='charactersAndProps__box'>
                                                <div className='imgContainer button'
                                                    onMouseEnter={() => setBoxFocus(loneBox2.src)}
                                                    onMouseLeave={() => setBoxFocus(-1)}
                                                    onClick={() => onBoxClick(loneBox2)}
                                                >
                                                    <img
                                                        src={loneBox2.src}
                                                        alt="props example"
                                                        style={{
                                                            height: `100%`,
                                                            width: `100%`
                                                        }}
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                <div className='imgContainer packageDeals__container'>
                                    <img loading="lazy"
                                        src={PackageDealsProps}
                                        alt="package deals"
                                    ></img>
                                </div>
                            </div>
                        </div>
                        {!mode.includes('mobile')
                            && <div className={`charactersAndProps__imagesContent ${mode}`}>
                                <div className='charactersAndProps__box'>
                                    <div className='imgContainer button'
                                        onMouseEnter={() => setBoxFocus(loneBox1.src)}
                                        onMouseLeave={() => setBoxFocus(-1)}
                                        onClick={() => onBoxClick(loneBox1)}
                                    >
                                        <img
                                            src={loneBox1.src}
                                            alt="props example"
                                            style={{
                                                height: `${loneBox1.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`,
                                                width: `${loneBox1.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`
                                            }}
                                        ></img>
                                    </div>
                                </div>
                                <div className='charactersAndProps__box__group'>
                                    <div className='charactersAndProps_box_row'>
                                        {propsFirstRow.map((image) => {
                                            return (
                                                <div key={image.src} className='imgContainer button'
                                                    onMouseEnter={() => setBoxFocus(image.src)}
                                                    onMouseLeave={() => setBoxFocus(-1)}
                                                    onClick={() => onBoxClick(image)}
                                                >
                                                    <img
                                                        src={image.src}
                                                        style={{
                                                            height: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`,
                                                            width: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`
                                                        }}
                                                        alt="props example"
                                                    ></img>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className='charactersAndProps_box_row'>
                                        {propsSecondRow.map((image) => {
                                            return (
                                                <div key={image.src} className='imgContainer button'
                                                    onMouseEnter={() => setBoxFocus(image.src)}
                                                    onMouseLeave={() => setBoxFocus(-1)}
                                                    onClick={() => onBoxClick(image)}
                                                >
                                                    <img
                                                        src={image.src}
                                                        style={{
                                                            height: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`,
                                                            width: `${image.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`
                                                        }}
                                                        alt="props example"
                                                    ></img>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className='charactersAndProps__box'>
                                    <div className='imgContainer button'
                                        onMouseEnter={() => setBoxFocus(loneBox2.src)}
                                        onMouseLeave={() => setBoxFocus(-1)}
                                        onClick={() => onBoxClick(loneBox2)}
                                    >
                                        <img
                                            src={loneBox2.src}
                                            alt="props example"
                                            style={{
                                                height: `${loneBox2.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`,
                                                width: `${loneBox2.src === boxIsFocused ? expandBoxMaxWidthHeight : boxMaxWidthHeight}%`
                                            }}
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className='charactersAndProps__backToTopContainer button'
                    onClick={() => scrollToTop()}
                >
                    <div
                        onMouseEnter={() => setBackToTopFocus(true)}
                        onMouseLeave={() => setBackToTopFocus(false)}
                        className='charactersAndProps__backToTopButtonContainer imgContainer'
                    >
                        <img loading="lazy"
                            style={{
                                height: `${backToTopButtonMaxWidthHeight}%`,
                                width: `${backToTopButtonMaxWidthHeight}%`,
                                marginBottom: backToTopButtonIsFocused ? 15 : 5
                            }}
                            src={UpButton}
                            alt="up arrow"
                        ></img>
                    </div>
                    <div className={'charactersAndProps__text'
                        + (backToTopButtonIsFocused ? ' focused' : '')}
                        onMouseEnter={() => setBackToTopFocus(true)}
                        onMouseLeave={() => setBackToTopFocus(false)}
                    >Back To Top</div>
                </div>
            </div>
            <Modal
                show={currImageIndex !== null}
                images={images}
                currIndex={currImageIndex}
                onClose={() => setImageIndex(null)}
                mode={mode}
            />
        </>
    )
}

export default CharactersAndProps;