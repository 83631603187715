import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import Divider from "../../resources/borders/divider-2.png";
import HireButton from "../../resources/buttons/hire-button.png";
import UpButton from "../../resources/buttons/arrow.png";
import FooterGraphic from "../../resources/graphics/footer-graphic.webp";
import Icon from "../../resources/icons/rubii-graphics-icon.png";

import './Footer.scss';

const Footer = (props) => {
    const {
        pageOptions,
        socialMediaLinks,
        scrollToTop,
        pageTitle,
        mode
    } = props;

    const [backToTopButtonIsFocused, setBackToTopFocus] = useState(false);

    let backToTopButtonMaxWidthHeight = 50;
    if (backToTopButtonIsFocused) {
        backToTopButtonMaxWidthHeight = backToTopButtonMaxWidthHeight * 1.5
    } else {
        backToTopButtonMaxWidthHeight = 50;
    }

    return (
        <div className={`footer ${mode}`}>
            <div className={`footer__top seperator__padding ${mode}`}>
                <div className='imgContainer'>
                    <img loading="lazy"
                        src={Divider}
                        alt="divider"
                    ></img>
                </div>
                <div className='footer__lists'>
                    {mode.includes('mobile')
                        ? <div className={`footer__pageOptions__list ${mode}`}>
                            {pageOptions.map((item) => {
                                return (
                                    <React.Fragment key={`footer-${item.name}`}>
                                        {item.name.toLowerCase() !== pageTitle && <div
                                            className='footer__pageOptions__list__item'
                                        >
                                            {item.type === 'link'
                                                ? <Link to={item.link}>{item.name}</Link>
                                                : <HashLink to={item.link}>{item.name}</HashLink>
                                            }
                                        </div>}
                                    </React.Fragment>
                                )
                            })}
                        </div>
                        : <div className='footer__socialMedia__list'>
                            {socialMediaLinks.map((item, index) => {
                                return (
                                    <a
                                        key={`footer-${item.href}-${index}`}
                                        href={item.href}
                                        className='footer__socialMedia__list__item'
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {item.icon}
                                    </a>
                                )
                            })}
                        </div>
                    }
                    {mode.includes('mobile') &&
                        <div className='imgContainer'>
                            <img
                                src={Icon}
                                alt="rubii graphics icon"
                            ></img>
                        </div>
                    }
                    {mode.includes('mobile')
                        ? <div className='footer__socialMedia__list'>
                            {socialMediaLinks.map((item, index) => {
                                return (
                                    <a
                                        key={`footer-${item.href}-${index}`}
                                        href={item.href}
                                        className='footer__socialMedia__list__item'
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {item.icon}
                                    </a>
                                )
                            })}
                        </div>
                        : <div>
                            {pageOptions.map((item) => {
                                return (
                                    <React.Fragment key={`footer-${item.name}`}>
                                        {item.name.toLowerCase() !== pageTitle && <div
                                            className='footer__pageOptions__list__item'
                                        >
                                            {item.type === 'link'
                                                ? <Link to={item.link}>{item.name}</Link>
                                                : <HashLink to={item.link}>{item.name}</HashLink>
                                            }
                                        </div>}
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    }
                </div>
                <div className='imgContainer'>
                    <img loading="lazy"
                        src={Divider}
                        alt="divider"
                    ></img>
                </div>
            </div>
            <div className={`footer__middle ${mode}`}>
                <Link to={'/contact'}>
                    <div className='hireContainer button'>
                        <div className='hireButtonContainer'>
                            <img loading="lazy"
                                src={HireButton}
                                alt="hire RUBII GRAPHICS"
                            ></img>
                        </div>
                    </div>
                </Link>
                {!mode.includes('desktop')
                    && <div className={`footer__designedByContainer ${mode}`}>
                        {mode.includes('tablet')
                            && <div className='imgContainer'>
                                <img
                                    src={Icon}
                                    alt="rubii graphics icon"
                                ></img>
                            </div>
                        }
                        <div className='footer__text'>Designed By Rubii Graphics</div>
                    </div>
                }
            </div>
            <div className={`footer__bottom ${mode}`}>
                <div className='imgContainer'>
                    <img loading="lazy"
                        src={FooterGraphic}
                        alt="diamond"
                    ></img>

                    <div className='footer__backToTopContainer button'
                        onClick={() => scrollToTop()}
                    >
                        <div
                            onMouseEnter={() => setBackToTopFocus(true)}
                            onMouseLeave={() => setBackToTopFocus(false)}
                            className='footer__backToTopButtonContainer imgContainer'
                        >
                            <img loading="lazy"
                                style={{
                                    height: `${backToTopButtonMaxWidthHeight}%`,
                                    width: `${backToTopButtonMaxWidthHeight}%`,
                                    marginBottom: backToTopButtonIsFocused ? 15 : 5
                                }}
                                src={UpButton}
                                alt="up arrow"
                            ></img>
                        </div>
                        <div className={'footer__text' + (backToTopButtonIsFocused ? ' focused' : '')}
                            onMouseEnter={() => setBackToTopFocus(true)}
                            onMouseLeave={() => setBackToTopFocus(false)}
                        >Back To Top</div>
                    </div>

                    {mode.includes('desktop')
                        && <div className={`footer__designedByContainer ${mode}`}>
                            <div className='imgContainer'>
                                <img
                                    src={Icon}
                                    alt="rubii graphics icon"
                                ></img>
                            </div>
                            <div className='footer__text'>Designed By Rubii Graphics</div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Footer;