import React, { useEffect, useState } from "react";
import "./App.scss";

// Packages
import { Route, Routes, useNavigate } from "react-router-dom";

import PhoneIcon from "./resources/icons/phone-icon.png";
import EmailIcon from "./resources/icons/email-icon.png";

// Components
import Home from "./HomeComponents/Home";
import Contact from "./ContactComponents/Contact";
import About from "./AboutComponents/About";

// Services components
import Service from "./ServicesComponents/Service";

const App = () => {
  const navigate = useNavigate();
  const pageOptions = [
    {
      name: 'Services',
      type: 'hash',
      link: '/#services',
      id: 'services'
    },
    {
      name: 'Contact',
      type: 'link',
      link: '/contact'
    },
    {
      name: 'About Us',
      type: 'link',
      link: '/about'
    },
  ];
  const contactOptions = [{
    type: "phone",
    icon: PhoneIcon,
    text: "205.267.7037"
  }, {
    type: "email",
    icon: EmailIcon,
    text: "Rubii@RubiiGraphics.com"
  }];
  const [mode, setMode] = useState('');

  const onBackButtonClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    const handleResize = () => {
      let width = window.innerWidth;

      if (width >= 992) {
        setMode('mode-desktop');
      } else if (width < 992 && width >= 650) {
        setMode('mode-tablet');
      } else if (width < 650 && width >= 367) {
        setMode('mode-mobile');
      } else {
        setMode('mode-xs-mobile');
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <Home
              pageOptions={pageOptions}
              mode={mode}
            />
          } />
        <Route
          path="/contact"
          element={
            <Contact
              pageOptions={pageOptions}
              contactOptions={contactOptions}
              onBackButtonClick={onBackButtonClick}
              mode={mode}
            />
          } />
        <Route
          path="/about"
          element={
            <About
              pageOptions={pageOptions}
              onBackButtonClick={onBackButtonClick}
              mode={mode}
            />
          } />
        <Route
          path="/services/:id"
          element={
            <Service
              pageOptions={pageOptions}
              mode={mode}
            />
          } />
      </Routes>
    </div>
  );
}

export default App;
