import React from 'react';

import Example from "../../resources/graphics/example-graphic.webp";
import Divider from "../../resources/borders/divider-2.png";
import Background from "../../resources/backgrounds/bg-water.webp";
import BackgroundMobile from "../../resources/backgrounds/bg-water-mobile.webp";

import './WelcomeSection.scss';

const WelcomeSection = (props) => {
    const {
        mode
    } = props;

    return (
        <div className={`welcomeSection ${mode}`}>
            <div className={`welcomeSection__top ${mode}`}>
                <div className='imgContainer'>
                    <img loading="lazy"
                        src={mode.includes('mobile') ? BackgroundMobile : Background}
                        alt="background water"
                    ></img>
                </div>
                <div className={mode.includes('desktop') ? 'container__largeMaxWidth' : 'sectionContainer'}>
                    <div className='welcomeSection__top__contentContainer'>
                        {!mode.includes('mobile')
                            && <div className='welcomeSection__leftSide imgContainer'>
                                <img loading="lazy"
                                    src={Example}
                                    alt='example graphic'
                                ></img>
                            </div>
                        }
                        <div className='welcomeSection__rightSide'>
                            <div className='welcomeSection__header'>
                                <span>Welcome to</span>
                                <span>Rubii Graphics!</span>
                            </div>
                            <div className='welcomeSection_text'>
                                This is YOUR one stop for every graphics need.
                                <br />
                                <br />
                                We offer a variety of personalized services ranging from Website design to games props. But don't trust our word for it-- check out our services below!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`welcomeSection__bottom seperator__padding ${mode}`}>
                <div className='imgContainer'>
                    <img loading="lazy"
                        src={Divider}
                        alt="divider"
                    ></img>
                </div>
            </div>
        </div>
    )
}

export default WelcomeSection;